import { styled } from '@mui/system'
import Button from '../button'

const ButtonStyled = styled(Button)`
	> .MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
		${({ theme }) => theme.breakpoints.up('md')} {
			font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		}
	}
	.MuiBadge-root {
		> .MuiSvgIcon-root {
			color: ${({ theme }) => theme.palette.primary.main};
			font-size: ${({ theme }) => theme.typography.pxToRem(19)};
			${({ theme }) => theme.breakpoints.up('md')} {
				font-size: ${({ theme }) => theme.typography.pxToRem(24)};
			}
		}
		.MuiBadge-badge {
			background: transparent;
			border: 1px solid ${({ theme }) => theme.palette.primary.main};
			color: ${({ theme }) => theme.palette.primary.main};
			font-size: ${({ theme }) => theme.typography.pxToRem(10)};
			min-height: 14px;
			min-width: 14px;
			width: 10px;
			height: 10px;
			&::before {
				border-radius: 50%;
				content: '';
				width: 18px;
				height: 18px;
				position: absolute;
				left: -3px;
				top: -3px;
				z-index: -1;
				border: 0px solid ${({ theme }) => theme.palette.primary.main};
			}
		}
	}
`

export { ButtonStyled }
