import { styled } from '@mui/system'
import MenuDropDown from '../menu-dropdown'

const shouldForwardProp = (prop) => !['top'].includes(prop)

const Overlay = styled('div', { shouldForwardProp })`
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	width: 100%;
	height: 100vh;
	left: 0;
	top: var(--header-height);
`

const MenuDropDownStyled = styled(MenuDropDown)`
	z-index: 1;
	border-top: 1px solid ${({ theme }) => theme.palette.grey.border};
`

export { Overlay, MenuDropDownStyled }
